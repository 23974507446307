$(document).ready(function () {
    $('.loaderWrapper.body').fadeOut(500, function () {
        $(this).remove();
    });

    $('img.svg').each(function () {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        $.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = $(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });

    $("#return-to-top").click(function () {
        $("body,html").animate({
            scrollTop: 0
        }, 500)
    });

    $("#show-interest").click(function () {
        console.log($('#interestDiv').offset());
        $("body,html").animate({
            scrollTop: $('#interestDiv').offset().top
        }, 500)
    });

    $('select[data-event-city-choices="true"]').change(function (e) {
        var selectedEventId = $('select[data-event-city-choices="true"]').find(':selected').attr('data-event-id');

        $('div[data-event-id-date]').each(function (index) {
            if (parseInt($(this).attr('data-event-id-date')) === parseInt(selectedEventId)) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });

        $('div[data-event-id-place]').each(function (index) {
            if (parseInt($(this).attr('data-event-id-place')) === parseInt(selectedEventId)) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });

        if ($('#login-redirect-href').length > 0) {
            $('#login-redirect-href').attr('href', updateQueryStringParameter($('#login-redirect-href').attr('href'), 'event_to', selectedEventId));
        }

        if ($('#event-question-form').length > 0) {
            $('#event-question-form').find('#event option').each(function (index) {
                if (parseInt($(this).attr('data-event-id')) === parseInt(selectedEventId)) {
                    $(this).attr('selected', 'selected');
                } else {
                    $(this).removeAttr('selected');
                }
            });
        }
    });

    function updateQueryStringParameter(uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
            return uri + separator + key + "=" + value;
        }
    }
});

