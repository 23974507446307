$('.navbar-toggle').on('click', function() {
    if($(this).hasClass('visible')) {
        $(this).removeClass('visible');
        $('.mobileCover').fadeOut(400);
        $('.mobileMenu').animate({
            height: '0px'
        }, 400);
    } else {
        $(this).addClass('visible');
        $('.mobileCover').fadeIn(400);
        $('.mobileMenu').animate({
            height: '268px'
        }, 400);

    }

});

$(document).ready(function(){
    if ($(window).width() < 992) {
        $("li > a").each(function(index, value) {
            var link = $(this).attr("href");
            $(this).parent().bind("click", function() {
                location.href = link;
            });
        });
    }

    // var $header = $('header');
    // var heightHeader = $header.height();
    // var windowHeight = $(window).height();
    // var lastScrollTop = 0;
    //
    // $(window).on('scroll', function() {
    //     var scroll = $(this).scrollTop();
    //     if (scroll > lastScrollTop) {
    //         // down
    //         if(scroll > heightHeader) {
    //             if($header.hasClass('is-slidedown')) {
    //                 $header.addClass('is-hidden is-slideup').removeClass('is-top is-slidedown');
    //             } else {
    //                 $header.addClass('is-hidden').removeClass('is-top');
    //             }
    //         }
    //     } else {
    //         // up
    //         if(scroll < heightHeader) {
    //             $header.addClass('is-top').removeClass('is-slideup is-hidden is-slidedown');
    //         } else if(scroll < (windowHeight / 2)) {
    //             if($header.hasClass('is-slidedown')) {
    //                 $header.addClass('is-hidden is-slideup').removeClass('is-slidedown');
    //             }
    //         } else {
    //             if($header.hasClass('is-hidden')) {
    //                 $header.addClass('is-slidedown').removeClass('is-hidden');
    //             }
    //         }
    //     }
    //     lastScrollTop = scroll;
    // });
});
