$(document).ready(function() {
  // Sprawdzenie na zaladowanie strony czy input jest zajety
  checkInput();
  $(document).on('focus', '.input-field input, .input-field textarea', function() {
    $(this).addClass('active');
    $(this).parent().find('label').addClass('active');
  });

  $(document).on('blur', '.input-field input, .input-field textarea', function() {
     if(!$(this).val()) {
       $(this).removeClass('active');
       $(this).parent().find('label').removeClass('active');
     } else {
       $(this).removeClass('active');
     }
  });

  $(document).on('click', '.form-group input', function() {
    $(this).nextAll().eq(1).remove();
  })
});

export function checkInput() {
  $('.input-field input, .input-field textarea').each(function() {
    if(!$(this).val()) {
      $(this).removeClass('active');
      $(this).parent().find('label').removeClass('active');
    } else {
      $(this).parent().find('label').addClass('active');
    }
  });
};
