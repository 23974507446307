var Cal = function(divId) {

    //Store div id
    this.divId = divId;

    this.DaysOfWeek = [
        'Pon',
        'Wt',
        'Śr',
        'Czw',
        'Pt',
        'Sob',
        'Nd',
    ];

    this.Months = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień' ];

    // Set the current month, year
    var d = new Date();

    var filledDays = [];

    this.currMonth = d.getMonth();
    this.currYear = d.getFullYear();

    this.currDay = d.getDate();

};

// nastepny miesiac
Cal.prototype.nextMonth = function() {
    if ( this.currMonth == 11 ) {
        this.currMonth = 0;
        this.currYear = this.currYear + 1;
    }
    else {
        this.currMonth = this.currMonth + 1;
    }
    this.showcurr();
};

// poprzedni miesiac
Cal.prototype.previousMonth = function() {
    if ( this.currMonth == 0 ) {
        this.currMonth = 11;
        this.currYear = this.currYear - 1;
    }
    else {
        this.currMonth = this.currMonth - 1;
    }
    this.showcurr();
};

// aktualny
Cal.prototype.showcurr = function() {
    this.showMonth(this.currYear, this.currMonth);

    var localMonth = parseInt(this.currMonth) + 1;

    if (localMonth < 10) {
        localMonth = '0' + localMonth;
    }

    $.when(getDays(this.currYear + '-' + localMonth)).done(data => {
        for (var key in data) {
            if (data[key] === false) {
                $(document).find('a[data="' + key + '"').addClass('empty');
            } else {
                $(document).find('a[data="' + key + '"').addClass('notEmpty');
            }
        }
    });
};

// Show month (year, month)
Cal.prototype.showMonth = function(y, m) {

    var d = new Date()
        // First day of the week in the selected month
        , firstDayOfMonth = new Date(y, m, 0).getDay()
        // Last day of the selected month
        , lastDateOfMonth =  new Date(y, m+1, 0).getDate()
        // Last day of the previous month
        , lastDayOfLastMonth = m == 0 ? new Date(y-1, 12, 0).getDate() : new Date(y, m, 0).getDate();

    var html = '<table>';

    // Write selected month and year
    html += `<thead><tr><td colspan="7"><span class="daySelected"></span>
            <span class="month">${this.Months[m]}</span> ${y}
            </td></tr></thead>`;


    // Write the header of the days of the week
    html += '<tr class="days">';
    for(var i=0; i < this.DaysOfWeek.length;i++) {
        html += `<td>${this.DaysOfWeek[i]}</td>`;
    }
    html += '</tr>';

    // Write the days
    var i=1;
    var today;
    do {

        var dow = new Date(y, m, i).getDay();
        // If Sunday, start new row
        if ( dow == 1 ) {
            html += '<tr>';
        }
        // If not Sunday but first day of the month
        // it will write the last days from the previous month
        else if ( i == 1 ) {
            html += '<tr>';
            var k = lastDayOfLastMonth - firstDayOfMonth;
            for(var j=0; j < firstDayOfMonth; j++) {
                html += '<td class="not-current">' + k + '</td>';
                k++;
            }
        }

        // Write the current day in the loop
        var chk = new Date();
        var chkY = chk.getFullYear();
        var chkM = chk.getMonth();
        // Adres jaki Adam chcesz mioc w hrefie, dni sa liczone normalnie, miesiace od 0 - przez Date'a.
        var adressToSend = i + '/' + m + '/' + y;

        // if (chkY == this.currYear && chkM == this.currMonth && i == this.currDay) {
        //     today = i;
        //     html += `<td class="today"><a class="day active" data="${adressToSend}">${i}</a></td>`;
        // } else {
        //     html += `<td class="normal"><a class="day" data="${adressToSend}">${i}</a></td>`;
        // }

        html += `<td class="normal"><a class="day" data="${adressToSend}">${i}</a></td>`;

        // If Sunday, closes the row
        if ( dow == 0 ) {
            html += '</tr>';
        }
        // If not Sunday, but last day of the selected month
        // it will write the next few days from the next month
        else if ( i == lastDateOfMonth ) {
            var k=1;
            for(dow; dow < 7; dow++) {
                html += '<td class="not-current">' + k + '</td>';
                k++;
            }
        }



        i++;
    }   while(i <= lastDateOfMonth);

    // Closes table
    html += '</table>';

    // Write HTML to the div
    document.getElementById(this.divId).innerHTML = html;

    $('.daySelected').html(function() {
        return today;
    });

    $('a.day').on('click', function() {

        //Tu adam trzeba bedzie robic zapytania
        console.log($(this).attr('data'));
        console.warn(document.getElementById('ulCit').getElementsByClassName('active')[0].getAttribute('href'));
        // .find('a[class="active"]').value()

        getEvents($(this).attr('data'), document.getElementById('ulCit').getElementsByClassName('active')[0].getAttribute('href').substring(1));

       $('a.day').removeClass('active');
       $(this).addClass('active');
        $('.daySelected').html(function() {
            return $(this).html();
        });
    });
};

// On Load of the window
window.onload = function() {

    // Start calendar
    if ($('#divCal').length > 0) {
        var c = new Cal("divCal");
        c.showcurr();

        // Bind next and previous button clicks
        getId('btnNext').onclick = function() {
            c.nextMonth();
        };
        getId('btnPrev').onclick = function() {
            c.previousMonth();
        };



        $('#ulCit li a').on('click', function(e) {
            e.preventDefault();

            $('#ulCit li a[class="active"]').removeClass('active');
            $(this).attr('class', 'active');

            getEvents($('a.day.active').attr('data'), $(this).attr('href').substring(1));
        });

        $('#ulCit select').on('change', function(e) {
            e.preventDefault();

            getEvents($('a.day.active').attr('data'), $(this).val());
        });
    }

}

// Get element by id
function getId(id) {
    return document.getElementById(id);
}


function getDays(date) {
    return $.ajax({
        method: "GET",
        url: '/get/events-cal',
        data: {date: date},
    });
}

function getEvents(date, city) {
    $.ajax({
        method: "GET",
        url: "/get/events",
        data: {date: date, city: city},
        success: function (data, textStatus, jqXHR) {
            $(document).find('#postsList').html(data);
        }
    });
}